function _define_property(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
import { css } from "@pigment-css/react";
import { deviceQueries } from "@stubhub/broadway-foundation";
export var container = css(function (param) {
  var theme = param.theme;
  var _obj;
  return _obj = {
    padding: "".concat(theme.vars.space[250], " ").concat(theme.vars.space[150], " ").concat(theme.vars.space[150], " ").concat(theme.vars.space[150]),
    // 12 px border radius
    borderRadius: theme.vars.border.radius[300]
  }, _define_property(_obj, "@media ".concat(deviceQueries.desktop), {
    width: "400px"
  }), _define_property(_obj, "background", theme.vars.colors.surface.primary), _define_property(_obj, "marginTop", theme.vars.space[200]), _obj;
});
export var title = css(function (param) {
  var theme = param.theme;
  return {
    marginBottom: theme.vars.space[150]
  };
});