function _define_property(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
import { css } from "@pigment-css/react";
import { typographyBaseStyles } from "@stubhub/broadway-typography";
export var listItemContainer = css(function (param) {
  var theme = param.theme;
  return {
    display: "flex",
    padding: theme.vars.space[100]
  };
});
export var infoContainer = css({
  flex: "1",
  alignContent: "center"
});
export var fixedFavButtonContainer = css({
  display: "flex",
  alignItems: "center",
  flex: "0 0 32px"
});
export var performerInfoText = css(function (param) {
  var theme = param.theme;
  return _define_property({}, "&:is(.".concat(typographyBaseStyles.base, ")"), {
    color: theme.vars.colors.surface.textSecondary
  });
});
export var subtitleSkeleton = css(function (param) {
  var theme = param.theme;
  return {
    marginTop: theme.vars.space[50]
  };
});
export var imageStyle = css(function (param) {
  var theme = param.theme;
  return {
    marginRight: theme.vars.space[150],
    // border radius 8px
    borderRadius: theme.vars.border.radius[200]
  };
});