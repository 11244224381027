import styled, { css as styledComponentsCss } from "styled-components";
import { Popover } from "@viagogo/core/ui";
import { css } from "@pigment-css/react";
export var calendarCTAStyle = {
  root: css(function (param) {
    var theme = param.theme;
    return {
      justifyContent: "space-between",
      borderRadius: theme.vars.border.radius[200],
      borderColor: theme.vars.colors.neutral.borderPrimary,
      width: "100%",
      "&:hover": {
        boxShadow: theme.vars.shadow.bottom[300]
      }
    };
  })
};
export var TimeText = styled.span.withConfig({
  componentId: "sc-7d2124b2-0"
})(["text-align:center;justify-self:center;", " ", ";@media ", "{font-size:11px;line-height:12px;font-weight:500;text-overflow:ellipsis;overflow:hidden;color:", ";}"], function (param) {
  var $disabledText = param.$disabledText;
  return $disabledText && styledComponentsCss(["color:", ";"], function (param) {
    var theme = param.theme;
    return theme.colors.textDisabled;
  });
}, function (param) {
  var $disabledText = param.$disabledText,
    _param_theme = param.theme,
    _param_theme_typography = _param_theme.typography,
    getStyles = _param_theme_typography.getStyles,
    variant = _param_theme_typography.variant;
  return getStyles($disabledText ? variant.labelSmall : variant.titleSmall);
}, function (param) {
  var media = param.theme.media;
  return media.phone;
}, function (param) {
  var theme = param.theme;
  return theme.colors.black;
});
export var TopLine = styled.div.withConfig({
  componentId: "sc-7d2124b2-1"
})(["display:flex;flex-direction:column;justify-content:center;align-items:center;gap:", ";"], function (param) {
  var theme = param.theme;
  return theme.spacing(4);
});
export var BodyContainer = styled.div.withConfig({
  componentId: "sc-7d2124b2-2"
})(["display:flex;flex-direction:column;justify-content:flex-start;gap:", ";height:100%;@media ", "{height:100%;align-items:center;}"], function (param) {
  var theme = param.theme;
  return theme.spacing(1);
}, function (param) {
  var media = param.theme.media;
  return media.phone;
});
export var CTAContainer = styled.div.withConfig({
  componentId: "sc-7d2124b2-3"
})(["--animation-:0.15s;display:flex;justify-content:space-between;width:100%;position:absolute;bottom:0;left:0;right:0;@media ", "{display:none;}"], function (param) {
  var media = param.theme.media;
  return media.phone;
});
export var StyledPopover = styled(Popover).withConfig({
  componentId: "sc-7d2124b2-4"
})(["& div[data-popper-placement]{z-index:3;}display:flex;align-items:center;cursor:pointer;width:100%;button{display:flex;align-items:center;width:100%;}span{display:flex;width:100%;}"]);
export var StyledPopoverMessage = styled.div.withConfig({
  componentId: "sc-7d2124b2-5"
})(["", ";padding:", ";border-radius:", ";"], function (param) {
  var _param_theme = param.theme,
    _param_theme_typography = _param_theme.typography,
    getStyles = _param_theme_typography.getStyles,
    variant = _param_theme_typography.variant;
  return getStyles(variant.labelExtraSmall);
}, function (param) {
  var theme = param.theme;
  return theme.spacing(1, 1);
}, function (param) {
  var theme = param.theme;
  return theme.borderRadius.s;
});