import { css } from "@pigment-css/react";
import { Col, Container, Link, Row } from "@viagogo/core/ui";
import styled, { css as styledComponentsCss } from "styled-components";
import { StyledMessage, StyledPopover, StyledQualifier } from "src/website-apps/shared/components/message/Message.styles";
import { FavoritesV2Button } from "src/website-apps/shared/components/FavoritesV2Buttons/FavoritesV2Button";
import { HottestEventLabel } from "src/website-apps/shared/components/event-grid-item/HottestEventLabel";
import { HoverButtonWrapper } from "src/website-apps/shared/components/new-card-hover-states/HoverButton.styled";
var StyledContainerBase = styled(Container).withConfig({
  componentId: "sc-d3cfdf10-0"
})(["border-style:solid;border-color:", ";border-width:0px;border-radius:", ";background-color:white;padding:", " 0;cursor:pointer;transition:background 0.2s ease-in-out;", ";"], function (props) {
  return props.theme.colors.borderPrimary;
}, function (props) {
  return props.theme.borderRadius.l;
}, function (props) {
  return props.theme.spacing(3);
}, function (param) {
  var $isHighlightedItem = param.$isHighlightedItem,
    $isNeutral = param.$isNeutral,
    theme = param.theme;
  return $isHighlightedItem && styledComponentsCss(["border-color:", ";border-width:1px;"], $isNeutral ? theme.palette.borderNeutralPrimary : theme.palette.borderBrandPrimary);
});
export var StyledContainer = styled(StyledContainerBase).withConfig({
  componentId: "sc-d3cfdf10-1"
})(["&:hover{", "}"], function (props) {
  return !props.disabled ? styledComponentsCss(["box-shadow:0px 0px 6px 0px rgba(0,0,0,0.25);background-color:", ";"], function (param) {
    var theme = param.theme;
    return theme.palette.brandSecondary;
  }) : "";
});
export var StyledContainerV2 = styled(StyledContainerBase).withConfig({
  componentId: "sc-d3cfdf10-2"
})(["@media ", "{&:hover{", "}}"], function (param) {
  var media = param.theme.media;
  return "".concat(media.tablet, ", ").concat(media.desktop);
}, function (props) {
  return props.disabled ? "" : styledComponentsCss(["background-color:", ";"], props.theme.colors.backgroundAccent700);
});
export var StyledContainerV3 = styled(StyledContainerBase).withConfig({
  componentId: "sc-d3cfdf10-3"
})(["@media ", "{&:hover{", " ", " button{background-color:", ";border-color:", ";color:", ";max-width:128px;& svg{width:16px;fill:", ";}}}}"], function (param) {
  var media = param.theme.media;
  return media.desktop;
}, function (param) {
  var disabled = param.disabled;
  return !disabled && styledComponentsCss(["box-shadow:0px 0px 0px 6px rgba(0,0,0,0.03);"]);
}, HoverButtonWrapper, function (param) {
  var $isNeutral = param.$isNeutral,
    theme = param.theme;
  return $isNeutral ? theme.palette.neutralPrimary : theme.palette.brandPrimary;
}, function (param) {
  var $isNeutral = param.$isNeutral,
    theme = param.theme;
  return $isNeutral ? theme.palette.neutralPrimary : theme.palette.brandPrimary;
}, function (param) {
  var $isNeutral = param.$isNeutral,
    theme = param.theme;
  return $isNeutral ? theme.palette.textOnNeutralPrimary : theme.palette.textOnBrandPrimary;
}, function (param) {
  var $isNeutral = param.$isNeutral,
    theme = param.theme;
  return $isNeutral ? theme.palette.textOnNeutralPrimary : theme.palette.textOnBrandPrimary;
});
export var eventDetailTitleBold = css(function (param) {
  var theme = param.theme;
  return {
    fontWeight: theme.vars.font.weight.bold
  };
});
export var eventDetailTitleSemiBold = css(function (param) {
  var theme = param.theme;
  return {
    fontWeight: theme.vars.font.weight.semibold
  };
});
export var eventDetailTitleDisabled = css(function (param) {
  var theme = param.theme;
  return {
    color: theme.vars.colors.text.primary
  };
});
export var EventDetailTitleDiv = styled.div.withConfig({
  componentId: "sc-d3cfdf10-4"
})(["", ";color:", ";"], function (param) {
  var theme = param.theme,
    $designSystemCompliant = param.$designSystemCompliant,
    $lowerFontWeight = param.$lowerFontWeight;
  return $designSystemCompliant ? theme.typography.getStyles(theme.typography.variant.labelMedium, $lowerFontWeight ? theme.typography.fontWeight.medium : theme.typography.fontWeight.bold) : theme.typography.getStyles(theme.typography.variant.labelMedium);
}, function (param) {
  var theme = param.theme,
    $disabled = param.$disabled;
  return $disabled ? theme.colors.textPrimary : theme.colors.textDarker;
});
export var EventDetailWrapperDiv = styled.div.withConfig({
  componentId: "sc-d3cfdf10-5"
})(["word-break:break-word;padding:", ";", ""], function (props) {
  return props.theme.spacing(0, 2, 0, 4);
}, function (param) {
  var $fullHeight = param.$fullHeight;
  return $fullHeight && styledComponentsCss(["height:100%;"]);
});
export var EventDetailSubtitleDiv = styled.div.withConfig({
  componentId: "sc-d3cfdf10-6"
})(["", ";", "{", ";color:", ";}", "{", ";color:", ";}", "{span{margin-top:-6px;}}color:", ";display:flex;justify-content:left;align-items:baseline;column-gap:", ";margin:", ";", " ", ""], function (param) {
  var _param_theme = param.theme,
    _param_theme_typography = _param_theme.typography,
    getStyles = _param_theme_typography.getStyles,
    variant = _param_theme_typography.variant,
    $matchDayOfWeekFont = param.$matchDayOfWeekFont;
  return getStyles($matchDayOfWeekFont ? variant.subtitle2 : variant.body1);
}, StyledMessage, function (param) {
  var _param_theme = param.theme,
    _param_theme_typography = _param_theme.typography,
    getStyles = _param_theme_typography.getStyles,
    variant = _param_theme_typography.variant;
  return getStyles(variant.body1);
}, function (props) {
  var _props_fontColor;
  return (_props_fontColor = props.fontColor) !== null && _props_fontColor !== void 0 ? _props_fontColor : props.theme.colors.textPrimary;
}, StyledQualifier, function (param) {
  var _param_theme = param.theme,
    _param_theme_typography = _param_theme.typography,
    getStyles = _param_theme_typography.getStyles,
    variant = _param_theme_typography.variant;
  return getStyles(variant.body1);
}, function (props) {
  var _props_fontColor;
  return (_props_fontColor = props.fontColor) !== null && _props_fontColor !== void 0 ? _props_fontColor : props.theme.colors.textPrimary;
}, StyledPopover, function (props) {
  var _props_fontColor;
  return (_props_fontColor = props.fontColor) !== null && _props_fontColor !== void 0 ? _props_fontColor : props.theme.colors.textPrimary;
}, function (props) {
  return props.theme.spacing(2);
}, function (param) {
  var theme = param.theme,
    $noTopMargin = param.$noTopMargin;
  return theme.spacing($noTopMargin ? 0 : 2, 0);
}, function (param) {
  var rightAlign = param.rightAlign;
  return rightAlign && styledComponentsCss(["margin-left:auto;"]);
}, function (param) {
  var showChevronTablet = param.showChevronTablet;
  return showChevronTablet && styledComponentsCss(["padding-right:10px;"]);
});
export var ChevronContainer = styled.div.withConfig({
  componentId: "sc-d3cfdf10-7"
})(["margin-right:", ";", ";height:100%;display:flex;justify-content:center;align-items:center;flex-direction:column;"], function (param) {
  var theme = param.theme;
  return theme.spacing(2);
}, function (param) {
  var marginRight = param.marginRight;
  return marginRight && styledComponentsCss(["margin-right:", ";"], marginRight);
});
export var StyledFavoritesV2Button = styled(FavoritesV2Button).withConfig({
  componentId: "sc-d3cfdf10-8"
})(["position:relative;margin:unset;top:unset;right:unset;"]);
export var StyledListItem = styled.li.withConfig({
  componentId: "sc-d3cfdf10-9"
})(["position:relative;list-style-type:none;border:1px solid ", ";border-radius:", ";", ";"], function (param) {
  var theme = param.theme;
  return theme.palette.neutralSecondaryActive;
}, function (param) {
  var theme = param.theme;
  return theme.borderRadius.l;
}, function (param) {
  var $isHighlightedItem = param.$isHighlightedItem,
    theme = param.theme;
  return $isHighlightedItem && styledComponentsCss(["border-style:solid;border-width:0px !important;border-color:", ";"], theme.palette.borderBrandPrimary);
});
export var StyledHottestEventLabel = styled(HottestEventLabel).withConfig({
  componentId: "sc-d3cfdf10-10"
})(["right:14px;top:-12px;"]);
export var SoldOutRow = styled(Row).withConfig({
  componentId: "sc-d3cfdf10-11"
})(["padding:", ";"], function (param) {
  var theme = param.theme,
    $extraBottomPadding = param.$extraBottomPadding;
  return theme.spacing(0.5, 3, $extraBottomPadding ? 1.5 : 0, 3);
});
export var FullHeightRow = styled(Row).withConfig({
  componentId: "sc-d3cfdf10-12"
})(["height:100%;"]);
export var StyledCol = styled(Col).withConfig({
  componentId: "sc-d3cfdf10-13"
})(["", ""], function (param) {
  var $noOverflow = param.$noOverflow;
  return $noOverflow && styledComponentsCss(["overflow:hidden;"]);
});
export var NoWrapRow = styled(Row).withConfig({
  componentId: "sc-d3cfdf10-14"
})(["flex-wrap:nowrap;"]);
export var DisabledLink = styled(Link).withConfig({
  componentId: "sc-d3cfdf10-15"
})(["pointer-events:none;display:none;"]);